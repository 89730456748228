/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

/* color pallet START */

$dark-blue: #394049;
$ligth-blue: #4cb0df;
$ligth-gray: #ebebeb;
$transparent-light-gray: #ebebeb85;
$white: #ffffff;
$black: #000000;
/* color pallet END */

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$footer-font-size: 12px;

a {
  text-decoration: none;
  &:hover {
    text-decoration-line: none;
  }
}

.blue-circle {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  display: block;
  background-color: #4cb0df;
  position: relative;
  margin: auto;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: white;
    font-family: $font-family-base;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
}

/* TODO Transformar num mixin */

.footer-shadow-box {
  /* referencia : https://www.cssmatic.com/box-shadow */
  /* referencia: para as paletes de cores https://www.w3schools.com/colors/colors_picker.asp */

  -webkit-box-shadow: inset 0px 20px 20px -15px rgba(34, 38, 43, 1);
  -moz-box-shadow: inset 0px 20px 20px -15px rgba(34, 38, 43, 1);
  box-shadow: inset 0px 20px 20px -15px rgba(34, 38, 43, 1);
}

@mixin transform($property) {
  -ms-transform: $property;
  -moz-transform: $property;
  -webkit-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin transition($property...) {
  -ms-transition: $property;
  -moz-transition: $property;
  -webkit-transition: $property;
  -o-transition: $property;
  transition: $property;
}
